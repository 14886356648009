<template>
  <div class="h-full min-h-screen overflow-y-auto relative">
    <div class="container mx-auto relative px-6 md:px-12 py-4 md:py-12">
      <header class="w-full flex flex-row justify-between items-center py-2">
        <div class="w-1/2 flex flex-col justify-start items-start md:flex-row md:items-center md:mr-2">
          <div class="flex flex-row justify-start items-center">
            <custom-brand-img v-if="customDomain" :type="customImgTypes.BG_LIGHT" img-class="custom-brand" />
            <sun-media-logo-dark v-else class="w-48" />
          </div>
        </div>
        <div class="relative w-1/2 flex justify-end flex-1 p-2">
          <asterix-user-menu />
        </div>
      </header>
      <section
        class="relative flex flex-col justify-start items-start w-full bg-white mb-2 p-2 sm:p-3 rounded rounded-lg shadow-md text-left sm:mt-1 md:mt-2 lg:mt-4"
      >
        <div class="flex w-full justify-center">
          <access-denied class="w-32 h-32 md:w-48 md:h-48 lg:w-64 lg:h-64" />
        </div>
        <div class="w-full">
          <div class="text-sm md:text-lg lg:text-xl xl:text-2xl text-gray-700 cursor-default text-center pb-4">
            {{ withoutPermissionText }}
          </div>
          <div class="text-sm sm:text-base md:text-lg text-orange-500 font-bold my-4 lg:my-8 text-center">
            <router-link class="w-auto" :to="'/'">
              <sun-button
                variant="pill"
                color="orange"
                class="w-auto px-6 mr-2 mb-1 text-sm whitespace-no-wrap rounded-full text-white bg-orange-600"
              >
                {{ goHomeText }}
              </sun-button>
            </router-link>
          </div>
        </div>
      </section>
      <asterix-footer />
    </div>
  </div>
</template>

<script>
import { COMMON_TEXTS } from '@/i18n/commonTexts';
import AsterixUserMenu from '@/components/molecules/shared/AsterixUserMenu';
import AsterixFooter from '@/components/molecules/shared/AsterixFooter';
import AccessDenied from '@/components/icons/AccessDenied';
import { isCustomDomain, customImgTypes } from '@/utils/domain';
import CustomBrandImg from '@/components/atoms/CustomBrandImg.vue';

export default {
  name: 'WithoutPermission',
  components: {
    AsterixUserMenu,
    AsterixFooter,
    AccessDenied,
    CustomBrandImg,
    SunMediaLogoDark: () => import('../../../components/icons/SunMediaLogoDark'),
  },
  data() {
    return {
      withoutPermissionText: COMMON_TEXTS.en.withoutPermissionText,
      goHomeText: COMMON_TEXTS.en.goHomeText,
      customDomain: isCustomDomain(),
      customImgTypes,
    };
  },
};
</script>

<style scoped>
.custom-brand {
  max-height: 100px;
}
</style>
